import React from 'react';

// import permissionConst from './constants/permissionConst';

const Dashboard = React.lazy(() => import('./components/Dashboard'));
const Notification = React.lazy(() => import('./components/Notification'));
const UserListing = React.lazy(() => import('./components/System/User'));
const RoleListing = React.lazy(() => import('./components/System/Role'));
const UpdatePassword = React.lazy(() => import('./components/Common/UpdatePassword'));
const GenerateReport = React.lazy(() => import('./components/Support/Generate'));
const ExportReport = React.lazy(() => import('./components/Support/Export'));
const DownloadReport = React.lazy(() => import('./components/DataDownload/Download'));
const AnonymousBet = React.lazy(() => import('./components/MemberLevelReport/AbnormalBet'));
const AnonymousBetAlert = React.lazy(() => import('./components/Alert/AbnormalBetAlert'));
const DailyETL = React.lazy(() => import('./components/Support/DailyETL'));
const LiveWagerDiscrepancy = React.lazy(() => import('./components/Support/LiveWagerDiscrepancy'));
const FAIP = React.lazy(() => import('./components/MemberLevelReport/FAIP'));
const FAIPWhitelist = React.lazy(() => import('./components/System/FAIPWhitelist'));
const FAMember = React.lazy(() => import('./components/MemberLevelReport/FAMember'));
const FAMemberAlert = React.lazy(() => import('./components/Alert/FAMemberAlert'));
const FAMemberWhitelist = React.lazy(() => import('./components/System/FAMemberWhitelist'));
const GeneralSetting = React.lazy(() => import('./components/System/GeneralSetting'));
const MemberProfileGroup = React.lazy(() => import('./components/MemberSetting/MemberProfileGroup'));
const FeatureOnOffSetting = React.lazy(() => import('./components/System/FeatureOnOffSetting'));
const AGB = React.lazy(() => import('./components/WagerLevelReport/AGB'));
const AGBAlert = React.lazy(() => import('./components/Alert/AGBAlert'));
const MemberBehaviourChange = React.lazy(() => import('./components/MemberLevelReport/MemberBehaviourChange'));
const RegularMemberTagging = React.lazy(() => import('./components/MemberLevelReport/RegularMemberTagging'));
const MemberGroupingDeviceId = React.lazy(() => import('./components/MemberLevelReport/MemberGroupingDeviceId'));
const MemberCategoryWhitelist = React.lazy(() => import('./components/System/MemberCategoryWhitelist'));
const ComboGhostFullCover = React.lazy(() => import('./components/WagerLevelReport/ComboGhostFullCover'));
const ComboGhostFullCoverAlert = React.lazy(() => import('./components/Alert/ComboGhostFullCoverAlert'));
const ComboGhostHighExposure = React.lazy(() => import('./components/WagerLevelReport/ComboGhostHighExposure'));
const ComboGhostHighExposureAlert = React.lazy(() => import('./components/Alert/ComboGhostHighExposureAlert'));
const MemberSummary = React.lazy(() => import('./components/MemberLevelReport/MemberSummary'));
const OneSidedEvent = React.lazy(() => import('./components/EventLevelReport/OneSidedEvent'));
const OneSidedEventAlert = React.lazy(() => import('./components/Alert/OneSidedEventAlert'));
const OpsAlert = React.lazy(() => import('./components/Alert/OpsAlert'));
const AGBMemberSummary = React.lazy(() => import('./components/MemberLevelReport/AGBMemberSummary'));
const MemberListing = React.lazy(() => import('./components/MemberSetting/MemberListing'));
const MemberGroupingMembersCookie = React.lazy(() => import('./components/MemberLevelReport/MemberGroupingMembersCookie'));
const BetRejectionMonitoring = React.lazy(() => import('./components/Monitoring/BetRejections'));
const FAMemberBlacklist = React.lazy(() => import('./components/System/FAMemberBlacklist'));
const MemberBetRejectionMonitoring = React.lazy(() => import('./components/MemberLevelReport/MemberBetRejections'));
const DataMapping = React.lazy(() => import('./components/System/DataMapping'));
const SFRawDataExtraction = React.lazy(() => import('./components/DataDownload/ProcessSFRawDataExtraction'));
const RiskSummary = React.lazy(() => import('./components/RiskManagement/RiskSummary'));
const EventSettlementTagging = React.lazy(() => import('./components/EventLevelReport/EventSettlementTagging'));
const TheoreticalWinlossMargin = React.lazy(() => import('./components/MemberLevelReport/TheoreticalWinlossMargin'));
const EventUptimeMonitoring = React.lazy(() => import('./components/Monitoring/EventUptimeMonitoring'));
const InternalAudit = React.lazy(() => import('./components/RiskManagement/InternalAudit'));
const OfflineExportUsage = React.lazy(() => import('./components/Support/OfflineExportUsage'));
const WagerReport = React.lazy(() => import('./components/WagerLevelReport/WagerReport'));
const MemberPerformance = React.lazy(() => import('./components/MemberLevelReport/MemberPerformance'));
const MemberProfiling = React.lazy(() => import('./components/MemberLevelReport/MemberProfiling'));
const MemberMassUpdate = React.lazy(() => import('./components/MemberSetting/MemberMassUpdate'));
const MemberDashboard = React.lazy(() => import('./components/MemberSetting/MemberDashboard'));
const ScoreProfile = React.lazy(() => import('./components/MemberSetting/ScoreProfile'));
const BQCost = React.lazy(() => import('./components/Support/BQCost'));
const GBIPISConnectivity = React.lazy(() => import('./components/Connectivity/GBIPISConnectivity'));
const ArberMemberSummary = React.lazy(() => import('./components/MemberLevelReport/ArberMemberSummary'));
const MLTaggingWagerLevel = React.lazy(() => import('./components/MemberLevelReport/MLTaggingWagerLevel'));
const MLTaggingUILevel = React.lazy(() => import('./components/MemberLevelReport/MLTaggingUILevel'));
const ArberBetReport = React.lazy(() => import('./components/WagerLevelReport/ArberBetReport'));
const BetAttemptReport = React.lazy(() => import('./components/RiskManagement/BetAttempt'));
const EventRecorReport = React.lazy(() => import('./components/DataReconReport/EventReconReport'));
const MemberCategorySetting = React.lazy(() => import('./components/MemberSetting/MemberCategorySetting'));
const LateBetMemberSummaryReconReport = React.lazy(() => import('./components/DataReconReport/LateBetMemberSummaryReconReport'));

const routes = [
    { path: '/', exact: true, name: 'Home' },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard },
    { path: '/notification', name: 'Notification', component: Notification },
    { path: '/system/userList', name: 'User', component: UserListing },
    { path: '/system/roleList', name: 'Role', component: RoleListing },
    { path: '/account/updatePassword', name: 'UpdatePassword', component: UpdatePassword },
    { path: '/support/generate', name: 'Generate', component: GenerateReport },
    { path: '/support/export', name: 'Export', component: ExportReport },
    { path: '/DataDownload/download', name: 'Download', component: DownloadReport },
    { path: '/memberlevelreport/abnormalBet', name: 'AbnormalBet', component: AnonymousBet },
    { path: '/alert/abnormalBetAlert', name: 'AbnormalBetAlert', component: AnonymousBetAlert },
    { path: '/support/dailyETL', name: 'DailyETL', component: DailyETL },
    { path: '/support/monLiveWagerDiscrepancy', name: 'LiveWagerDiscrepancy', component: LiveWagerDiscrepancy },
    { path: '/memberLevelReport/FAIP', name: 'FAIP', component: FAIP },
    { path: '/system/FAIPWhitelist', name: 'System', component: FAIPWhitelist },
    { path: '/system/generalSettingList', name: 'System', component: GeneralSetting },
    { path: '/system/featureOnOffSettingList', name: 'System', component: FeatureOnOffSetting },
    { path: '/wagerLevelReport/agb', name: 'AfterGoalBet', component: AGB },
    { path: '/alert/agbAlert', name: 'AfterGoalBetAlert', component: AGBAlert },
    { path: '/memberLevelReport/memberbehaviourchange', name: 'MemberBehaviourChange', component: MemberBehaviourChange },
    { path: '/memberLevelReport/regularmembertagging', name: 'MemberBehaviourChange', component: RegularMemberTagging },
    { path: '/memberLevelReport/memberGroupingDeviceId', name: 'MemberGroupingDeviceId', component: MemberGroupingDeviceId },
    { path: '/memberLevelReport/FAMember', name: 'FAMember', component: FAMember },
    { path: '/alert/FAMemberAlert', name: 'FAMemberAlert', component: FAMemberAlert },
    { path: '/system/FAMemberWhitelist', name: 'System', component: FAMemberWhitelist },
    { path: '/monitoring/BetRejection', name: 'BetRejection', component: BetRejectionMonitoring },
    { path: '/system/MemberCategoryWhitelist', name: 'System', component: MemberCategoryWhitelist },
    { path: '/membersetting/ProfileGroup', name: 'MemberListing', component: MemberProfileGroup },
    { path: '/wagerLevelReport/ComboGhostFullCover', name: 'ComboGhost', component: ComboGhostFullCover },
    { path: '/alert/ComboGhostFullCoverAlert', name: 'ComboGhostFullCoverAlert', component: ComboGhostFullCoverAlert },
    { path: '/wagerLevelReport/ComboGhostHighExposure', name: 'ComboGhost', component: ComboGhostHighExposure },
    { path: '/alert/ComboGhostHighExposureAlert', name: 'ComboGhostHighExposureAlert', component: ComboGhostHighExposureAlert },
    { path: '/eventLevelReport/OneSidedEvent', name: 'OneSidedEvent', component: OneSidedEvent },
    { path: '/alert/OneSidedEvent', name: 'OneSidedEvent', component: OneSidedEventAlert },
    { path: '/alert/OpsAlert', name: 'OpsAlert', component: OpsAlert },
    { path: '/memberLevelReport/agbMemberSummary', name: 'AfterGoalBetMemberSummary', component: AGBMemberSummary },
    { path: '/membersetting/MemberListing', name: 'MemberListing', component: MemberListing },
    { path: '/memberLevelReport/memberGroupingMembersCookie', name: 'MemberGroupingMembersCookie', component: MemberGroupingMembersCookie },
    { path: '/system/FAMemberBlacklist', name: 'FAMemberBlacklist', component: FAMemberBlacklist },
    { path: '/memberLevelReport/MemberBetRejections', name: 'MemberBetRejection', component: MemberBetRejectionMonitoring },
    { path: '/system/DataMapping', name: 'DataMapping', component: DataMapping },
    { path: '/memberLevelReport/MemberSummary', name: 'MemberSummary', component: MemberSummary },
    { path: '/dataDownload/SFRawDataExtraction', name: 'SFRawDataExtraction', component: SFRawDataExtraction },
    { path: '/riskManagement/RiskSummary', name: 'RiskSummary', component: RiskSummary },
    { path: '/eventLevelReport/EventSettlementTagging', name: 'EventSettlementTagging', component: EventSettlementTagging },
    { path: '/memberLevelReport/TheoreticalWinlossMargin', name: 'TheoreticalWinlossMargin', component: TheoreticalWinlossMargin },
    { path: '/monitoring/EventUptimeMonitoring', name: 'EventUptimeMonitoring', component: EventUptimeMonitoring },
    { path: '/riskmanagement/internalAudit/:dateFrom/:dateTo/:userName', name: 'InternalAudit', component: InternalAudit },
    { path: '/riskmanagement/internalAudit', name: 'InternalAudit', component: InternalAudit },
    { path: '/support/OfflineExportUsage', name: 'OfflineExportUsage', component: OfflineExportUsage },
    { path: '/wagerLevelReport/WagerReport', name: 'WagerReport', component: WagerReport },
    { path: '/memberLevelReport/MemberPerformance', name: 'MemberPerformance', component: MemberPerformance },
    { path: '/memberLevelReport/MemberProfiling', name: 'MemberProfiling', component: MemberProfiling },
    { path: '/membersetting/MemberMassUpdate', name: 'MemberMassUpdate', component: MemberMassUpdate },
    { path: '/membersetting/MemberDashboard/:memberCode/:companyName/:dateFrom/:dateTo', name: 'MemberDashboard', component: MemberDashboard },
    { path: '/membersetting/MemberDashboard', name: 'MemberDashboard', component: MemberDashboard },
    { path: '/membersetting/ScoreProfile', name: 'ScoreProfile', component: ScoreProfile },
    { path: '/support/BQCost', name: 'BQCost', component: BQCost },
    { path: '/connectivity/GBIPISConnectivity', name: 'GBIPISConnectivity', component: GBIPISConnectivity },
    { path: '/memberLevelReport/ArberMemberSummary', name: 'ArberMemberSummary', component: ArberMemberSummary },
    { path: '/memberLevelReport/MLTaggingWagerLevel/:memberCode/:companyName/:companyID', name: 'MLTaggingWagerLevel', component: MLTaggingWagerLevel },
    { path: '/memberLevelReport/MLTaggingWagerLevel', name: 'MLTaggingWagerLevel', component: MLTaggingWagerLevel },
    { path: '/memberLevelReport/MLTaggingUILevel/:memberCode/:companyName/:companyID', name: 'MLTaggingUILevel', component: MLTaggingUILevel },
    { path: '/memberLevelReport/MLTaggingUILevel', name: 'MLTaggingUILevel', component: MLTaggingUILevel },
    { path: '/wagerLevelReport/ArberBetReport', name: 'ArberBetReport', component: ArberBetReport },
    { path: '/riskManagement/BetAttemptReport', name: 'BetAttemptReport', component: BetAttemptReport },
    { path: '/dataReconReport/EventReconReport', name: 'EventReconReport', component: EventRecorReport },
    { path: '/membersetting/categorySettings', name: 'MemberCategorySetting', component: MemberCategorySetting },
    { path: '/dataReconReport/LateBetMemberSummaryReconReport', name: 'LateBetMemberSummaryReconReport', component: LateBetMemberSummaryReconReport },
];

export default routes;
